.external-report-container {
  align-items: start;
  align-self: stretch;
  border-radius: 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.2);
  border-color: rgba(223, 230, 229, 1);
  border-style: solid;
  border-width: 1px;
  background-color: var(--background-foundation-background-1, #fff);
  display: flex;
  max-width: 774px;
  min-width: 300px;
  flex-direction: column;
  font-size: 14px;
  color: var(--Text-Dark-Text-Dark, #1a1a1a);
  font-weight: 400;
  line-height: 150%;

  @media (max-width: 991px) {
    .container {
      padding-right: 20px;
    }
  }

  .item {
    background-color: var(--Backgrounds-Surface, #fff);
    display: flex;
    gap: 16px;
    padding: 12px 16px;
    justify-content: space-between;
    width: 100%;
    &:hover {
      text-decoration: none;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }

  .icon {
    width: 24px !important;
    color: #1a1a1a99;
  }

  .label {
    font-family: Calibre-R, sans-serif;
    flex: 1;
    color: #1a1a1a;
  }
}

.continueButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  padding: 32px 0px 48px;
  text-align: center;
  white-space: nowrap;
  line-height: 1;
  color: rgba(26, 32, 44, 0.5);
  @media (max-width: 768px) {
    padding-left: 20px;
    margin-bottom: 40px;
  }
}

.site-location-header-button{
  display: flex;
  align-items: center;
  h1{
    font-size: 14px;
    margin:0;
    color:#80BBAD;
    height: 14px;
  }
}

.site-selection-search{
  .MuiAutocomplete-clearIndicator{
    // display: flex;
    height: auto;
    position: relative;
    margin-right: 2px;
    &::after{
      position: absolute;
      content:'';
      width: 1px;
      height: 60%;
      background-color: grey;
      top:0;
      bottom:0;
      right: 0;
      margin: auto;
    }
  }
}