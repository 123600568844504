.approval-chain-link {
  --top: 24px;
  align-items: start;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--Misc-Dividers-Divider, rgba(0, 63, 45, 0.15));
  background-color: #fff;
  z-index: 0;
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: start;
  padding: 10px 12px 12px;
  margin-top: var(--top);

  &.border-active {
    border: 1px solid #ff9742;
  }

  &.border-completed {
    border: 1px solid #003F2D1A;
  }

  &.border-in-queue{
    border: 1px dashed #E7E7F9; 
  }

  .future-card{
    border: 1px solid #778F9C;
  }

  .approval-sequence {
    display: flex;
    min-width: 240px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
  }

  .approval-row {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: start;
  }

  .approval-inner {
    display: flex;
    min-height: 40px;
    width: 100%;
    align-items: start;
    gap: 8px;
    justify-content: start;
  }

  .icon-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: start;
    width: 24px;
  }

  .lv-set {
    display: flex;
    min-width: 240px;
    flex-direction: column;
    justify-content: start;
    flex: 1;
    flex-basis: 0%;
    margin-block: 4px 5px;
    gap: 8px;
    font:
      14px/1 Calibre-R,
      sans-serif;
  }

  .value {
    color: var(--Text-Dark-Text-Dark, #1a1a1a);
    font-weight: 500;
  }
}
