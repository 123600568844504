:root {
  --default-approval-top-position: 48px;
}

.approval-dashboard {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--default-approval-top-position));
    background-color: #f5f7f7;    
  }
  
  .dashboard-header {    
     
    h4{
      font-size: 24px;
    }

    @media screen and (min-width: 1280px) {
      background-color: #fff;      
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 20px;  
    }

  }
  
  .dashboard-content {
    display: flex;
    flex: 1;
    // overflow: hidden;
    @media screen and (min-width: 1280px) {
      padding: 18px;  
    }
  }
  
  .approval-details-section {
    flex: 1;
    margin-left: 20px;
    display: none;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      display: flex;
    }
    @media screen and (min-width: 768px) and (max-width: 1279px) {
      margin-top: -55px;
      margin-left: 0;
      border-left: 1px solid #003F2D26;
      height: calc(100vh - var(--default-approval-top-position));      
      
    }
    
    @media screen and (min-width: 1280px) {
      height: calc(100vh - 170px - var(--default-approval-top-position));      
    }
  }