@import 'styles/base/variables';
.date-selector {
  .MuiTextField-root{
    width: 100%;
    margin-top: 8px;
    margin-bottom: 4px;
    label{
      font-size: 0.875rem;
    }
    label[data-shrink='true'] {
      -webkit-transform: translate(14px, -6px) scale(0.857) !important;
      -moz-transform: translate(14px, -6px) scale(0.857) !important;
      -ms-transform: translate(14px, -6px) scale(0.857) !important;
      transform: translate(14px, -6px) scale(0.857) !important;
      transform-origin: top left !important;
      z-index: 1;
      max-width: 120%;
    }
  }
  fieldset {
    border-color: $color-default;
  }
  .Mui-focused {
    fieldset {
      border-color: $color-primary;
    }
  }
  label.Mui-focused {
    color: $color-primary;
  }
  .MuiInputAdornment-positionStart {
    .MuiIconButton-root {
      padding: 0;
    }
  }
  .Mui-error, .Mui-error svg{
    color:rgb(244, 67, 54) !important;
  }

}
.MuiPickersYear-root {
  display: inline-block;
  width: 75px;
  text-align: center;
  height: 38px;
}
