.new-project-add-option-confirm-dialog {
  top: 20% !important;
  .MuiDialog-scrollPaper {
    align-items: baseline;
  }
  .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 154px);
   
  }
  .MuiDialog-paperWidthMd {
    max-width: 780px;
  }
  
  & .np-add-option-title {
    padding-bottom: 5px;
  }
  & .np-add-option-content {
    padding-top: 5px;
  }
  & .add-option-err-msg {
    font-size: 0.75rem;
    margin-bottom: 0 !important;
    color: #f44336;
    width: 100%;
  }
  .MuiFormControl-marginNormal {
    margin-top: 0;
    margin-bottom: 8px;
    height: 70px;
   /* background-color: lightpink; */
   
  }
  
}

.personnel-btn {
  margin-right: 0 !important;
}
.loader-custom {
  margin-left: 5px;
  margin-right: 5px;
}
.backdrop-custom {
  z-index: 1301;
  color: #fff;
  opacity: 0.5 !important;
}
@media screen and (max-width: 375px) {
 
  .new-project-add-option-confirm-dialog {
    .MuiDialog-paperScrollPaper {
      padding: 19px !important;
    }
    & .new-project-add-option-content {
      padding-top: 0;
      padding-bottom: 0px;
      height: 100px;
    }
  }
  
}