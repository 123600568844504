@import 'styles/base/variables';
@import 'styles/base/mixin';

.MuiSnackbarContent-message{
    width: 90% !important;
    text-align: center;
    font-size: 1rem;
}

.MuiSnackbarContent-action{
width: 10%;
margin: 0px !important;
padding-left: 0px !important;
// padding-bottom: 35px;
justify-content: flex-end;
}
.alertSpacing {
    min-height: 62px;
    padding: 16px 16px 14px 16px !important;
    color: #000 !important;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.20);
}

.alertSuccess{
    border: 1px solid $color-success-main !important;
    background-color: $color-success-light !important;
}
.alertFailure {
    border: 1px solid $color-error-main !important;
    background-color: $color-error-light !important;
}  
.alertWarning{
    background-color: #dc8e27;
}
.alertInformation{
   background-color: #005596;
}
 

