.request-details-info {
  .MuiStepLabel-iconContainer.Mui-active {
    border: 1px solid #003f2d;
    width: 30px;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 20px;
  }
  .MuiStepIcon-text {
    display: none;
  }
  .icon-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 6px;
  }
  .icon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }

  // MyComponent.scss
  .wrapper {
    display: flex;
    gap: 0;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
  .sidebar {
    justify-content: space-between;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 991px) {
      display: none;
    }
  }
  .logo {
    aspect-ratio: 0.29;
    object-fit: auto;
    object-position: center;
    width: 56px;
    align-self: center;
  }
  .separator {
    border-top: 1px solid rgba(0, 63, 45, 0.1);
    background-color: #fff;
    margin-top: 848px;
    width: 56px;
    height: 56px;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
  .content {
    background-color: #f5f7f7;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .header {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 28px;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  .breadcrumbs {
    align-self: start;
    display: flex;
    gap: 4px;
    font-size: 12px;
    color: rgba(26, 26, 26, 0.6);
    font-weight: 400;
    letter-spacing: 0.06px;
    line-height: 133%;
    padding: 2px 0;
    @media (max-width: 991px) {
      white-space: initial;
    }
  }
  .breadcrumb {
    font-family: Calibre-R, sans-serif;
  }
  .title {
    color: #000;
    letter-spacing: -0.32px;
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .requestButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: #003f2d;
    gap: 8px;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    line-height: 100%;
    margin: auto 0;
    padding: 6px 16px;
  }
  .buttonIcon {
    aspect-ratio: 1;
    object-fit: auto;
    object-position: center;
    width: 24px;
  }
  .buttonLabel {
    font-family: Calibre-R, sans-serif;
    margin: auto 0;
  }
  .main {
    background-color: #f5f7f7;
    // padding: 16px;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-left: 0;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .section {
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.14);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 16px;
    // margin-bottom: 16px;
  }
  .sectionTitle {
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid rgba(0, 63, 45, 0.15);
    color: #1a1a1a;
    letter-spacing: -0.24px;
    font-size: 16px;
    font-weight: 500;
    padding: 6px 0;
    text-align: start;
  }
  .detailItem {
    display: flex;
    margin-top: 4px;
    gap: 16px;
    padding: 8px 4px;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }
  .empty-value {
    background: white;
    display: block;
    margin-block: -2px;
    margin-right: -8px;
    padding-left: 2px;
  }

  .work-order-details {
    max-height: 148px;
    overflow: auto;
    * {
      margin: 0;
    }
  }
  .detailLabel {
    color: rgba(26, 26, 26, 0.6);
    font-family: Calibre-R, sans-serif;
    flex: 0.45 1;
  }
  .detailValue {
    color: #1a1a1a;
    font-family: Calibre-R, sans-serif;
    flex: 1;
  }
  .status {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0 8px 0 6px;
  }
  .statusIcon {
    background-color: #50af78;
    border-radius: 50%;
    width: 12px;
    height: 12px;
  }
  .aside {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-left: 20px;
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .system {
    display: flex;
    gap: 12px;
    padding: 8px 16px;
    @media (max-width: 991px) {
      padding-right: 20px;
    }
  }
  .section-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: column;
  }

  .section-item,
  .section-1,
  .section-2 {
    flex: 0 0 calc(50% - 10px);
    margin: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .section-item > div,
  .section-1 > div,
  .section-2 > div {
    height: auto;
  }

  

}

.request-details-screen-container {
  .approval-request-details-container {
    padding-bottom: 0;

    .approval-request-detail-tab-panel.request-info{
      > .MuiBox-root{
        padding: 0;
      }
    }

  }
}
