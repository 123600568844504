.approval-list-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 63, 45, 0.15) !important;
    background-color: #fff;
    & > div{
      align-self: baseline;
    }
  
    &.pending {
      background-color: #fff9c4;
    }
  
    &.approved {
      background-color: #e8f5e9;
    }
  
    &.rejected {
      background-color: #ffebee;
    }
  }
  
  .status-icon {
    margin-right: 16px;
  }