.approvals-chain-container {
  --top:24px;
  background: var(--Backgrounds-Surface, #fff);
  max-width: 540px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .approval-chain-history-tab-item {
    border: none;
    &.Mui-selected {
      border-radius: 4px; // Optional: Add border radius
      background-color: #dfe6e5;
      color: #003f2d;
    }
  }

  .title-wrap {
    display: flex;
    margin-top: 12px;
    flex-direction: column;
    justify-content: center;
    font:
      400 14px/1 Calibre-R,
      -apple-system,
      Roboto,
      Helvetica,
      sans-serif;
      & .lv-set {
       align-items: center;
       gap: 16px;
       width: auto;
      }
      & .label{
        flex-grow: 0;

      }
  }

  .lv-set {
    display: flex;
    min-height: 20px;
    width: 100%;
    align-items: start;
    gap: 16px;
    justify-content: start;
  }

  .label {
    color: var(--Text-Dark-Text-Dark-2nd, rgba(26, 26, 26, 0.65));
    text-align: start;
  }

  .value {
    color: var(--Text-Dark-Text-Dark, #1a1a1a);
    text-align: start;
    flex-grow: 1;
  }

  .approvals-wrap {
    --top: 24px;
    position: relative;
    display: flex;
    margin-top: 12px;
    width: 100%;
    flex-direction: column;
    justify-content: start;
    & > .approval-chain-link:first-child {
      margin-top: 0;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      width: 1px;
      height: calc(100% - var(--top));
      background: var(--Misc-Dividers-Divider, #003f2d26);
      z-index: 0;
    }
  }
}
