
@font-face {
    font-family: 'Futuramdbt';
    src: url('../../futura-pt/dn7.ttf') format('ttf'), url('../../futura-pt/futura-pt-n7') format('woff2'), url('../../futura-pt/dn7') format('woff'), url('../../futura-pt/futura-pt-n7') format('truetype'), url('../../futura-pt/dn7.ttf') format('ttf');
font-weight: normal;
font-style: normal;

}

@font-face {
    font-family: 'FuturaLight';
    src: url('../../futura-pt/dn4.ttf') format('ttf'),url('../../futura-pt/dn4') format('woff'), url('../../futura-pt/futura-pt-n4') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Futuramdbt-Export';

    src:  url('../../futura-pt/futura-pt-n7.otf') format('woff2'), url('../../futura-pt/dn7.otf') format('woff'), url('../../futura-pt/dn7.ttf') format('ttf'), url('../../futura-pt/futura-pt-n7.otf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'FuturaLight-Export';
    src: url('../../futura-pt/dn4.otf') format('woff'),  url('../../futura-pt/futura-pt-n4.otf') format('otf');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'FuturaLight-Export2';
    src: url('../../futura-pt/dn4.otf') format('woff'),  url('../../futura-pt/futura-pt-n4.otf') format('otf'),  url('../../futura-pt/dn4.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'FuturaMediumBold';

    src: url('../fonts/futuramedium_bold.woff2') format('woff2'), url('../fonts/futuramedium_bold.woff') format('woff');
font-weight: normal;
font-style: normal;
}
