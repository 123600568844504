/*Theme Green Styles*/
$app-primary-white: #006a4d !default;
$secondary-white: #FF6D00;

$sidebar-white: rgba($app-primary-white, 0.25) !default;
$sidebar-text-white: #868e96 !default;

//-Base-scss
.white .right-arrow {
  color: $app-primary-white;
  &:after {
    color: $app-primary-white;
  }
}

//- Bootstrap file Style
.white {
  a,
  .card-link,
  .pjm-link,
  .pjm-link.text-primary {
    color: $app-primary-white;
    &:focus,
    &:hover {
      color: darken($app-primary-white, 10%);
    }
  }

  & .text-primary {
    color: $app-primary-white !important;
  }

  & .page-link {
    color: $app-primary-white;
    &:focus,
    &:hover {
      color: $app-primary-white;
    }
  }

  & .page-heading .breadcrumb-item.active {
    color: $app-primary-white;
  }

  & .bg-primary,
  & .badge-primary {
    background-color: $app-primary-white !important;
    color: $white !important;
  }

  & .btn-primary {
    background-color: $app-primary-white;
    border-color: $app-primary-white;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($app-primary-white, 5%) !important;
      border-color: darken($app-primary-white, 5%) !important;
      color: $white !important;
    }
  }

  & .bg-primary {
    &.lighten-4 {
      background-color: #C8E6C9 !important;
    }

    &.lighten-3 {
      background-color: #A5D6A7 !important;
    }

    &.lighten-2 {
      background-color: #81C784 !important;
    }

    &.lighten-1 {
      background-color: #66BB6A !important;
    }

    &.darken-1 {
      background-color: #43A047 !important;
    }

    &.darken-2 {
      background-color: #388E3C !important;
    }

    &.darken-3 {
      background-color: #2E7D32 !important;
    }

    &.darken-4 {
      background-color: #1B5E20 !important;
    }

    &.accent-1 {
      background-color: #B9F6CA !important;
    }

    &.accent-2 {
      background-color: #69F0AE !important;
    }

    &.accent-3 {
      background-color: #00E676 !important;
    }

    &.accent-4 {
      background-color: #00C853 !important;
    }
  }
}

//Secondary
.white {
  .pjm-link.text-secondary {
    color: $secondary-white;
    &:focus,
    &:hover {
      color: darken($secondary-white, 10%) !important;
    }
  }

  & .text-secondary {
    color: $secondary-white !important;
  }

  & .bg-secondary,
  & .badge-secondary {
    background-color: $secondary-white !important;
    color: $white !important;
  }

  & .btn-secondary {
    background-color: $secondary-white;
    border-color: $secondary-white;
    color: $white;

    &:hover,
    &:focus,
    &:active {
      background-color: darken($secondary-white, 5%) !important;
      border-color: darken($secondary-white, 5%) !important;
      color: $white !important;
    }
  }
}

//_header.scss
.white .app-main-header {
  background: $white !important;
  border-bottom: 1px solid #AAAAAA;
  & .app-logo {
    & div {
      color: #000000;
    }
  }

  & .border-thin {
      border-color: black;
      border-style: solid;
      border-width: 2px;
      border-radius: 50%;
    & div{
      color: black;
    }
    }
  & .search-dropdown input {
    @media screen and (max-width: 991px) {
      background-color: $white;
    }
  }
}

//_right-sidebar.scss
.white .color-theme-header {
  background-color: $app-primary-white;
}

//_sidebar.scss
.white .side-nav {
  background-color: $white !important;
  color: $sidebar-text-white !important;
  @include box-shadow(0 1px 4px 0 rgba(0, 0, 0, 0.15));

  & .user-profile {
    background-color: $app-primary-white;
    margin-right: 20px;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: $pjm-border;
    position: relative;
    z-index: 2;
  }

  & .customizer {
    border-bottom: solid 1px lighten($sidebar-text-white, 35%);
  }

  & .user-detail {
    & .user-name {
      color: $app-primary-white;
    }
  }
}

.white .nav-section {

  & .nav-header {
    color: $sidebar-text-white;
    border-color: $gray-400;
  }
}

.white .nav-collapse {

  & .nav-collapse-btn {
    color: $sidebar-text-white;

    &:focus,
    &:hover {
      background-color: $white;
      color: $app-primary-white;
    }
  }

  &.open {
    background-color: $white;

    & .nav-collapse-btn {
      background-color: $white;
      color: $app-primary-white;

      &:focus,
      &:hover {
        background-color: $white;
        color: $app-primary-white;
      }
    }
  }
}

.white .nav-collapse {
  & .nav-menu-item .nav-menu-link {
    color: $sidebar-text-white;

    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $app-primary-white;
    }

    &:after {
      background-color: $app-primary-white;
    }
  }

  &.open {
    & .nav-menu-item .nav-menu-link.active {
      background-color: $sidebar-white;
      color: $app-primary-white;
    }
  }
}

.white .nav-menu-item {
  & .nav-menu-link {

    &:focus,
    &:hover,
    &.active {
      background-color: $white;
      color: $app-primary-white;
    }
  }
}

/*Header top Navbar Styles*/
.white .navbar-nav {
  & li {
    &:hover > a,
    &:focus > a,
    &:hover > .nav-link,
    &:focus > .nav-link {
      color: $app-primary-white;
    }

    & a,
    & .nav-link {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      color: $app-primary-white;
    }
  }

  & li.nav-item > a,
  & li.nav-item > .nav-link {
    color: $white;
  }

  & li.nav-item:hover > a,
  & li.nav-item:focus > a,
  & li.nav-item > a:hover,
  & li.nav-item > a:focus,
  & li.nav-item.active > a,
  & li.nav-item:hover > .nav-link,
  & li.nav-item:focus > .nav-link,
  & li.nav-item > .nav-link:hover,
  & li.nav-item > .nav-link:focus,
  & li.nav-item.active > .nav-link {
    color: $secondary-white;
  }

  ul.sub-menu {
    & li a.active,
    & li.active > a,
    & li .nav-link.active,
    & li.active > .nav-link {
      color: $app-primary-white;
    }
  }

  & .nav-arrow {
    & > a:before,
    & > .nav-link:before {
      color: $sidebar-text-color;
    }

    & a:hover,
    & a:focus,
    & .nav-link:hover,
    & .nav-link:focus {
      &:before {
        color: $app-primary-white;
      }
    }

    &:hover > a:before,
    &:focus > a:before,
    &.active > a:before,
    &:hover > .nav-link:before,
    &:focus > .nav-link:before,
    &.active > .nav-link:before {
      color: $app-primary-white;
    }
  }
}

.white .app-top-nav {

  & .navbar-nav {
    & li.nav-item > a,
    & li.nav-item > .nav-link {
      color: $white;
    }

    & li.nav-item:hover > a,
    & li.nav-item:focus > a,
    & li.nav-item > a:hover,
    & li.nav-item > a:focus,
    & li.nav-item.active > a,
    & li.nav-item:hover > .nav-link,
    & li.nav-item:focus > .nav-link,
    & li.nav-item > .nav-link:hover,
    & li.nav-item > .nav-link:focus,
    & li.nav-item.active > .nav-link {
      color: $secondary-white;
    }
  }
}

.white .app-header-horizontal {
  & .app-main-header-top {
    @include box-shadow(none !important);
  }
}

.white .header-notifications .app-notification {
  & .pjm-list-link {

    @include hover-focus-active {
      color: $app-primary-white;
      border-color: $app-primary-white;
    }
  }
}

//_app-module.scss
.white .module-nav {
  & li {
    & a.active {
      border-color: $app-primary-white;
    }

    & .pjm-link.active {
      color: $app-primary-white;
    }
  }
}

//_calendar.scss
.white .rbc-event {
  background-color: $app-primary-white;
}

.white .rbc-event.rbc-selected {
  background-color: darken($app-primary-white, 10%);
}

.white .rbc-slot-selection {
  background-color: rgba($app-primary-white, 0.7);
}

.white .rbc-toolbar button:active,
.white .rbc-toolbar button.rbc-active {
  background-color: rgba($app-primary-white, 0.9);
  border-color: $app-primary-white;
}

.white .rbc-toolbar button:active:hover,
.white .rbc-toolbar button.rbc-active:hover,
.white .rbc-toolbar button:active:focus,
.white .rbc-toolbar button.rbc-active:focus {
  background-color: rgba($app-primary-white, 0.9);
  border-color: $app-primary-white;
}

.white .rbc-toolbar button:focus {
  background-color: rgba($app-primary-white, 0.9);
  border-color: $app-primary-white;
}

.white .rbc-toolbar button:hover {
  background-color: rgba($app-primary-white, 0.9);
  border-color: $app-primary-white;
}

//_chat.scss
.white .chat-sidenav-title {
  color: $app-primary-white;
}

.white .chat-user-item {
  &.active,
  &:hover {
    background-color: lighten($app-primary-white, 45%);
  }
}

//_dashboard.scss
.white .contact-list {
  & i {
    color: $app-primary-white;
  }
}

//The link which when clicked opens the collapsable ChatUserList
.white .Collapsible__trigger {
  background: $app-primary-white;
}

.white .categories-list {
  & li a:focus,
  & li a:hover,
  & li.active a {
    color: $app-primary-white;
  }
}

//_login.scss
.white .login-content .form-control {
  &:focus {
    border-color: $app-primary-white;
  }
}

//_portfolio.scss
.white .filter-with-bg-color ul li {
  .pjm-link {
    border-color: $app-primary-white;

    &:hover,
    &:focus,
    &.active {
      background-color: $app-primary-white;
    }
  }
}

//_card.scss
.white .profile-intro {
  & .icon {
    color: $app-primary-white;
  }
}

.white .social-link {
  & li.active a,
  & li a:hover,
  & li a:focus {
    color: $secondary-white;
  }
}

//_tables.scss
.white .actions {
  color: $secondary-white;
}

.white .table-hover tbody tr:hover {
  background-color: rgba($app-primary-white, 0.075);
}

//Border Color

.white .border-primary {
  border-color: $app-primary-white !important;
}

// login page content

.white .app-logo-content {
  background-color: $app-primary-white;
}

.white .app-social-block {
  & .social-link button {
    border: solid 1px $app-primary-white;
    color: $app-primary-white;

    &:hover,
    &:focus {
      color: $white;
      background-color: $app-primary-white;
    }
  }
}

/*Button Group Styles*/
.white .btn-group,
.white .btn-group-vertical {
  > .pjm-btn {
    &.active {
      background-color: $app-primary-white;
      border-color: $app-primary-white;
    }
  }

  > .pjm-flat-btn {
    background-color: transparent;
    border-color: transparent;
    &.active {
      background-color: transparent;
      color: $app-primary-white;
      &:hover,
      &:focus {
        background-color: rgba($app-primary-white, 0.12);
        color: $app-primary-white;
      }
    }
  }
}

/*Classic Dashboard Styles*/
.white .pjm-fillchart-btn-close,
.white .pjm-onchart .pjm-badge-up,
.white .pjm-task-list-item:hover .pjm-text-hover {
  color: $app-primary-white;
}

.white .pjm-entry-title:before {
  background-color: $app-primary-white;
}

.white .pjm-card-ticketlist {
  & .pjm-task-list-item:hover .pjm-task-item-title,
  .pjm-link {
    color: $app-primary-white;
  }
}

.white .slick-dots li.slick-active button:before {
  border-color: $app-primary-white;
}

//Nav Styles
.white .nav-pills .nav-link.active,
.white .nav-pills .show > .nav-link {
  color: $white !important;
  background-color: $app-primary-white;
}

// Gradient Color Class
.white .bg-gradient-primary {
  @include gradient-directional($app-primary-white, lighten($app-primary-white, 16%), 0deg);
}

.white .bg-gradient-primary-x {
  @include gradient-x(darken($app-primary-white, 10%), lighten($app-primary-white, 16%), 70%, 100%);
}

//Profile style
.white .pjm-profile-banner {
  background-color: $app-primary-white;
  color: $white;

  & .pjm-link {
    color: $white;

    &:hover,
    &:focus {
      color: $secondary-white;
    }
  }
}

.white .dropdown-item {

  &.active,
  &:active {
    background-color: $app-primary-white;
    color: $white;
  }

  &[class*="text-"] {

    &.active,
    &:active {
      background-color: transparent;
    }
  }
}
