@import "~styles/base/mixin";
@import "~styles/base/variables";

.search-bar {
  background-color: $white;
  position: relative;

  label:not(.MuiInputLabel-shrink) {
    @include transform(translate(12px, 12px) scale(1));
    font-size: $font-size-14;
  }

  .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0;
  }
  .form-control:focus {
    color: #495057;
    background-color: $white;
    border-color: $color-primary !important;
    outline: 0;
    @include box-shadow(0 0 0 0.1rem rgb(0 63 45 / 25%));
  }
  .search-icon {
    position: absolute;
    top: 3px !important;
    left: auto !important;
    right: 6px !important;
  }
}

