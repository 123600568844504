.approval-list-section, .green .approval-list-section  {
    flex: 0;   
    width: 100%;
    flex-basis: 100%;
    overflow: hidden;   
    background-color: #fff;
    min-width: 360px;
    .selected-list{
      background-color: #003F2D26;
    }

    @media screen and (min-width: 960px) {
      width: 400px;
      flex-basis: 400px;      
    }

    @media screen and (min-width: 1280px) {
      // padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);      
    }
  }
  
  .approval-list-header {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    padding-top: 4px;
    background-color: #f5f7f7;
    @media screen and (min-width: 1280px) {
      padding: 16px;  
      background-color: #fff;
    }
  }