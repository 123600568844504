.approval-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    hr{
      margin: 0 8px;
    }
    .MuiButton-root {
      &:hover {
        margin: 0 !important;
      }
      &:not(:last-child) {
        margin: 0 !important;
      }
    }
  }