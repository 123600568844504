@import 'styles/base/variables';
@import 'styles/base/mixin';
.emerald {
  &.select-box {
    margin-top:8px;
    margin-bottom: 4px;
    fieldset {
      border-color: $color-default;
    }
    .Mui-focused {
      fieldset {
        border-color: $color-primary;
      }
    }
    label, .MuiSelect-selectMenu {
      font-size: $font-size-base !important;
    }
    .MuiFormLabel-root.Mui-error {
      color: $color-error-main !important;
    }
  }
}