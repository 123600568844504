.delete-project-message {
    padding: 10px;
    color: $color-text-dark;
    font-size: 1rem;
    font-weight: 400;
    display: flex;
  }
  .zmdi-hc-fw{
    line-height: 1;
  }
i {
  &.zmdi-delete{
    color: $color-error-main;
  }
}
ul[role="menu"]{
  .zmdi-delete{
    color: $color-error-main;
    &+span {
      color: $color-error-dark;
    }
  }
}