@import 'styles/base/variables';
.search-widget {
    margin-right: 15px;
    input[type="search"] {
        width: 260px;
    }
    .search-input {
        padding-right: 50px !important;
        padding-left: 16px !important;
    }
    .search-icon {
        top: 3px !important;
        left: 6px !important;
        color: #ced4da;
    }
    .project-list-search {
        left: auto !important;
        right: 6px !important;
    }
}

.search-filter-card {

    select::-ms-expand {
        display: none;
    }
    .fdm-select-container {
        margin-bottom: -6px;
    }

    .btn-def {
        min-width: 100px;
        height: 36px;
    }
    .filter-btn-pos {
        margin-right: 0 !important;
    }
}


.filter-widget-dropdown-menu {
    width: 240px;
    transform: translate3d(-2px, -35px, 0px) !important;
    .filter-widget-dropdown-menu-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba($black, 10%);
        font-size: 0.75rem;
        color: rgb(0, 159, 111);
        font-weight: 900;
        line-height: 1.2;
        margin-top: 12px;
        position: relative;
        .fdm-header-content {
            margin-bottom: 12px;
            padding: 0.5rem 0.9375rem;
            .fdm-filter-icon {
                font-size: 1.2rem;
            }
        }
        .fdm-close-btn {
            position: absolute;
            top: -15px;
            right: 1px;
            background-color: transparent;
            font-size: 0.75rem
        }
    }

    .filter-widget-dropdown-menu-body {
        font-size: 0.75rem;
        margin-top: 1rem;
        margin-left: 1rem;
        margin-right: 0;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        .fdm-select-container {
            margin-bottom: 20px;
        }
        .fdm-form-field {
            font-size: 0.75rem;
            margin-top: 0.35rem;
            -webkit-appearance: none;
            -moz-appearance: none;
            position: relative;
            width: 190px;
        }

        .fdm-down-arrow {
            position: relative;
            top: -28px;
            right: -165px;
            color: rgb(0, 159, 111);
        }

    }
}

.search-filter-card {
    // z-index: 1;
    padding: 20px 20px 10px 20px;
   /* box-shadow: 0 1px 2px 0 var(--shadow, rgba(170, 183, 178, 0.6));*/
    box-shadow: 0px 0px 5px 0 var(--shadow, rgba(170, 183, 178, 0.6));
    margin-left : 7px;
    margin-right: -5px;

    .search-filter-card-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .filter-grid-btn {
        margin-right: 1rem !important;
        &.search-bar {
            margin-right: 0 !important;
            &+.MuiButton-containedPrimary{
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
        .MuiOutlinedInput-root{
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
        &+.filter-grid-btn {
                &.MuiButton-containedPrimary{
                margin-right: 16px !important;
                height: 39px !important;
            } 
            &+.filter-grid-btn{
                &.dropdown{
                    span {
                        .MuiButton-outlinedPrimary{
                            height: 39px !important;
                        }
    
                    }
                }
    
            }
        }
    }
    .btn-pos {
        margin-left: auto;
    }
}

.filter-listing-chips {
    .filter-listing-clear-btn {
        display: inline-flex;
        font-size: 1rem;
        color:  $color-primary;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        margin-bottom: 10px;
        font-weight: 400;;
        padding: 8px 16px;
        border-radius: 4px;
        &:active,
        &:hover,
        &:visited {
            color:  $color-primary;
            background-color:  rgba($color-primary, 8%);
        }
    }
    .selected-filter-chip {
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: rgba($color-primary, 10%);//#ecf5f2;
        font-size: 12px;
    }
}


@media screen and (max-width: 768px) {
    .search-widget {

        input[type="search"] {
            width: 242px;
        }
    }

    .search-filter-card {
        .filter-grid-btn {
            margin-right: 10px;
        }
        .btn-pos {
            margin-left: 0;
        }
    }
}
@media screen and (max-width: 576px) {
    .search-widget {

        input[type="search"] {

            width: 260px;
        }
    }
    .search-filter-card {
       .search-search-filter-card-content {
           justify-content: center;
       }
        .filter-grid-btn {
            margin-left: 7px;
            margin-right: 7px !important;
        }

    }
    .btn-def {
       width: 120px;
    }
}

@media screen and (max-width: 414px) {
    .btn-def {
        width: 260px;
     }
}