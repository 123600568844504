//@import '~@emerald/ngstyle/Components/input';
@import 'styles/base/variables';
// form styles
.green {
  .emerald-input.autocomplete {
    label {
      color: $color-default;
      font-weight: 400;
      font-size: $font-size-base;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      padding: 0;
      display: block;
      transform-origin: top left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 70px);
      position: absolute;
      left: 0;
      top: -3px;
      -webkit-transform: translate(12px, 20px) scale(1);
      -moz-transform: translate(12px, 20px) scale(1);
      -ms-transform: translate(12px, 20px) scale(1);
      transform: translate(12px, 20px) scale(1);
      -webkit-transition:
        color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      transition:
        color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
        max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
      z-index: 0;
      pointer-events: auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      //pointer-events: none;
      &.Mui-focused {
        color: $color-primary;
      }
    }
    .MuiInputLabel-shrink,
    label[data-shrink='true'] {
      -webkit-transform: translate(14px, -6px) scale(0.857) !important;
      -moz-transform: translate(14px, -6px) scale(0.857) !important;
      -ms-transform: translate(14px, -6px) scale(0.857) !important;
      transform: translate(14px, -6px) scale(0.857) !important;
      transform-origin: top left !important;
      z-index: 1;
      max-width: 120%;
    }
    .Mui-focused {
      &label {
        color: $color-primary;
      }
      fieldset {
        border-color: $color-primary;
      }
    }
    input:focus {
      outline: 0;
    }
    fieldset {
      text-align: left;
      position: absolute;
      bottom: 0;
      right: 0;
      top: -5px;
      left: 0;
      margin: 0;
      padding: 0 8px;
      pointer-events: none;
      border-radius: inherit;
      border-style: solid;
      border-width: 1px;
      overflow: hidden;
      min-width: 0;
      border-color: $color-default;
    }
    fieldset.Mui-error {
      border-color: $color-error-main !important;
    }
    .Mui-error {
      .MuiInputAdornment-root{
        color: $color-error-main !important;
      }
      fieldset {
        border-color: $color-error-main !important;
      }
      color: $color-error-main !important;
    }

    .async-search-icon, .hidden{
      position: relative;
      top:2px
    }
    /*.MuiFormLabel-root.Mui-focused {
      color: $app-primary-green;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $app-primary-green;
    }*/
    &:has(.MuiInputBase-adornedStart) label {
      left: 30px;
      top:-3px;
      &.MuiInputLabel-shrink,
      &[data-shrink='true'] {
        -webkit-transform: translate(-16px, -6px) scale(0.857) !important;
        -moz-transform: translate(-16px, -6px) scale(0.857) !important;
        -ms-transform: translate(-16px, -6px) scale(0.857) !important;
        transform: translate(-16px, -6px) scale(0.857) !important;
      }
    }
    &.multi-select-autocomplete{
      .MuiInputBase-input{
        padding-top:6px;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
      }
      }
      [class$="IndicatorsContainer"]{
        display: none;
      }     
      
    }
    .disabled-prop-async-auto{
      .MuiTextField-root{
        background-color: #1a1a1a08;
      }
      
      label{
        background-color: transparent !important;
      }
    } 
  }
  .MuiInputBase-input {
    [class$='-Input'] {
      margin: 0;
      padding-top: 0;
    }
  }

  .non-portal-target{
    .custom-style{
      margin-top: 0;
    } 
    
  }
  /*.valueContainer > div > div > div {
    color: red;
  }*/
  .valueContainer > div {
    border-radius: 5px;
    padding: 0 2px;
    margin-right: 5px;
  }
}

/*.orange {
  .emerald-input {
    .MuiFormLabel-root.Mui-focused {
      color: $app-primary-orange;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $app-primary-orange;
    }
  }
}*/
