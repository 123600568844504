
@import 'styles/base/variables';
/* Dropzone Styles */
.document-file-upload {
  background:  #FFF;

  .dropzone-card {
    // overflow-y: unset;
    position: relative;
    right: 0;

    .dropzone {
      &-card {
        position: relative;
      }

      margin-bottom: 7px;
      margin-top: 7px;

      &-file-btn {
        font-size: 14px;
        line-height: 20px;
        width: 99%;
        height: 88px;
        border: 2px dashed #1A1A1A;
        margin: 0 auto;
        padding: 20px;
        border-radius: 5px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn {
        margin-top: 20px;
      }

      .Component-closeButton-48 {
        top: 8px;
        color: #7F8480;
        right: 8px;
        position: absolute;
        top: 1.5%;
      }

      .MuiDialogTitle-root {
        padding: 0px;
      }
    }
  }

  .padding-title {
    padding-left: 1%;
  }

  .padding-title-right {
    padding-right: 4%;
  }

  .MuiTypography-body1,
  .MuiPaper-root {
    color: #1a1a1a;
  }

  .dropzone-content {
    // max-height: calc(100vh - 400px);
    position: relative;
    right: 0;
    .activity-table {
      position: relative;
      overflow-y: auto;
      box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.14);
      overflow-y: auto;
      table {
        border-radius: 4px;
        width: 100%;
        border-collapse: collapse;
        overflow-y: auto;

        thead {
          position: sticky;
          top: 0;
          background-color: #F5F7F7;
          z-index: 1;
        }

        tbody {
          max-height: calc(100vh - 500px);
          overflow-y: auto;
          display: block;
        }

        .chip-container-tr{
          &:not(:has(.selected-filter-chip)){
            display: none;
          }
          .MuiChip-deleteIcon{
            margin: 0;
            margin-right: 10px;
            height: 20px;
            padding: 3px;
            background:#767676;
            color: white;
            border-radius: 50%;
          }    
          
        }

        .has-tags{
          border-bottom: none;
          box-shadow: none !important;
          & + tr {
            border-top: none;
            td {
              padding-top: 0;
            }
          }
        }

        tr {
          display: table;
          width: 100%;
          table-layout: fixed;
          border-bottom: 1px solid rgba(0, 63, 45, 0.15);
          box-shadow: 0px -1px 0px 0px #003F2D26 inset !important;
        }

        th,
        td {
          padding: 16px 10px;
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        th.column-file,
        td.column-file {
          width: 20%;
        }

        th.column-description,
        td.column-description {
          width: 20%;
        }

        th.column-tags,
        td.column-tags {
          width: 20%;
        }

        th.column-handoverpackage,
        td.column-handoverpackage {
          width: 30%;
        }

        th.column-actions,
        td.column-actions {
          width: 10%;
        }
      }
    }

    .file-required-err {
      margin-top: 0;

      .file-required-err-msg {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
        color: $color-error-main;
        width: 100%;
      }
    }

    .attached-file-list,
    ul.attached-file-list,
    ol.attached-file-list,
    .attached-file-list.MuiList-subheader {
      list-style: none !important;
      overflow: auto;
      padding-left: 0 !important;
    }

    .MuiListItemIcon-root {
      color: rgba(0, 0, 0, 0.54);
      display: inline-flex;
      min-width: 30px;
      flex-shrink: 0;
    }
  }

  .styles_overlay__CLSq-:not(.exportModalWindow):not(.customModalPopupBorder),
  .react-responsive-modal-container:not(.exportModalWindow):not(.customModalPopupBorder) {
    background: rgba(25, 24, 24, .2);

    .validationErrorMessage.fc-black {
      color: #1a1a1a;
    }

    div:first-child {
      border-top: none;
      overflow-y: auto;
      border-top-color: $color-primary;
      border-top-width: 5px;
    }
  }

  .confirmationModalButton {
    margin: auto;
    display: flex;
    justify-content: center;

    button:not(:last-child) {
      margin-right: 15px;
    }
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: transparent !important;
  }

  .textbox-file-upload {
    border-color: rgba(26, 26, 26, 0.6);

    .MuiFormControl-marginNormal {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .PrivateNotchedOutline-legendLabelled-28 {
      height: 0px;
    }

    .MuiInputBase-input {
      font-size: 0.875rem;
      height: 36px;
    }

    .MuiOutlinedInput-input {
      padding: 0px 14px;
    }

    .emerald-input.textbox label {
      top: -16px;
    }
    .auto-complete-container-doc{
      color: rgba(0, 0, 0, 0.87);
      line-height: 1.1876em;
      height: 36px;
      border: 1px solid rgba(26, 26, 26, 0.6);      
      border-radius: 4px;
      .MuiInput-root{
        flex-wrap: nowrap;
        &::before{
          border: none;
        }
        &:hover::before{
          border:none;
        }
        .MuiCircularProgress-root{
          display: flex;
        }
        &:after{
          border:none;
        }
      }
     &:hover{
      border: 1px solid #003F2D;
     } 
    }
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input{
      width: 180px;
    }
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"][class*="MuiInput-marginDense"] .MuiAutocomplete-input:first-child{
      padding: 0px 14px 0px;
    }
    tr:has(.selected-filter-chip){
      border: none !important;
    }
    .file-upload-chips {
      overflow-x: auto;
      padding-top: 4px !important;
    }
  }
  .MuiDialogTitle-root {
    padding: 0px 0px 34px 0px !important;
  }
  .actionBtn {
    display: flex;
    position: absolute;
    justify-content: flex-end;
    margin-left: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #fff;
  }
  .pl-documents-dialog .MuiSvgIcon-root {
    margin-top: 0 !important;
    margin-right: 0 !important;
  }
  .MuiSvgIcon-root {
    margin-top: 0;
    margin-right: 0;  
  }
  .MuiIconButton-root {
    margin-top: 0;
    margin-right: 0;
  }
}

.auto-complete-container-doc-paper {
  z-index: 2300;
  li{
    width: 100%;
    label{
      align-items: center;
    }
  }
  ul > li{
    padding: 0;
    height: 48px;
    line-height: 24px;
  }
}

.pl-documents-dialog .MuiDialogTitle-root .MuiSvgIcon-root {
  margin-top: 0;
  margin-right: 0;
}

.MuiSnackbar-anchorOriginBottomCenter{
  z-index: 99999 !important;
}