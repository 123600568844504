
.alertSpacing {
    min-height: 62px;
    padding: 16px 16px 14px 16px !important;
    color: #000 !important;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.20);
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    font-size: 1rem;
}

.alertSuccess{
    border: 1px solid #50AF78;
    background-color: #E6F4EC !important;
    .icon {
        color: #50AF78;
    }
}
.alertFailure {
    border:  1px solid #ff543e !important;
    background-color: #FBEEEE !important;
    .icon {
        color: #ff543e;
    }
}  
.alertWarning{
    background-color: #dc8e27;
    .icon{
        color: #dc8e27;
    }
}
.alertInformation{
   background-color: #005596;
}
 

