.pl-documents-dialog {  
  .MuiDialog-paperFullScreen {
    height: 100%;
    width: 60%;
    position: absolute;
    bottom: 0;
  }
  &-content {
    padding: 16px 34px;
  }
  &.warning {
    .MuiDialog-paper {
      border-top-color: #FF0000;
    }
  }
    .MuiSvgIcon-root {
      position: relative;
      z-index: 1;
      transition: transform 0.3s;
    }
    .MuiIconButton-root {
      margin-top: 24px;
      margin-right: 40px;
    }
  
    .MuiDialogTitle-root.pl-documents-dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  
    .MuiDialogTitle-root.pl-documents-dialog-title h6 {
      margin: 0 12px;
    }
    .pl-documents-dialog-content {
      .MuiSvgIcon-root {
        margin-top: 0px;
        margin-right: 0px;   
      }
      .MuiIconButton-root {
        margin-top: 0px;
        margin-right: 0px;
      }
    }
}



@media (max-width: 768px) {
  .pl-documents-dialog {      
    .MuiDialog-paperFullScreen {
        width: 95%;
      }
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .pl-documents-dialog {
    .MuiDialog-paperFullScreen {
      width: 75%;
    }
  }
}

@media (min-width: 993px) and (max-width: 1199px) {
  .pl-documents-dialog{
    .MuiDialog-paperFullScreen {
      width: 65%;
    }
  }
}